// MainWidget.scss

.main-widget {
  display: flex;
  justify-content: flex-start;
  padding: 8px;
  width: 268px;
  border: 1px solid;
  border-radius: 4px;

  &.light {
    background-color: #ffffff;
    border-color: #4056f7;
  }

  &.dark {
    background-color: #130e3a;
    border-color: #fff;
  }

  .logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      max-width: 38px;
      width: 100%;
    }

    .text-container {
      border-right: 2px solid;
      padding-left: 8px;
      padding-right: 8px;

      &.light {
        color: #4056f7;
        border-color: #4056f7;
      }

      &.dark {
        color: #fff;
        border-color: #fff;
      }

      &--md {
        font-size: 16px;
      }

      &--extrabold {
        font-weight: 600;
      }

      &--sm {
        font-size: 14px;
      }

      p.uppercase {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .rating-container {
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .average-rating {
      font-size: 8px;
      font-weight: 200;

      &.light {
        color: #67647f;
      }

      &.dark {
        color: #d1d0d5;
      }

      .score {
        font-weight: 600;
        color: #f71278;
      }
    }

    .rating-items-container {
      margin-top: 2px;
      margin-bottom: 2px;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 20px;

      &.light {
        background-color: #ffe3ef;
      }

      &.dark {
        background-color: #000000;
      }

      svg {
        width: 10px;
      }
      .text-secondary-700 {
        color: #da0e69;
      }

      .text-neutral-200 {
        color: #b1afba;
      }
    }

    .reviews {
      font-size: 8px;
      font-weight: 200;

      &.light {
        color: #67647f;
      }

      &.dark {
        color: #d1d0d5;
      }

      .review-count {
        font-weight: 700;
        color: #f71278;
      }
    }
  }
}
