// CompactWidget.scss

.compact-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 160px;
  border: 1px solid;
  border-radius: 4px;

  &.light-theme {
    background-color: #ffffff;
    border-color: #4056f7;
  }

  &.dark-theme {
    background-color: #130e3a;
    border-color: #67647f;
  }

  .logo-container {
    width: 160px;

    .logo {
      width: 100%;

      &.light-theme {
        color: #130e3a;
      }

      &.dark-theme {
        color: #ffffff;
      }
    }
  }

  .rating-container {
    border-radius: 20px;
    width: 80%;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    svg {
      width: 10px;
    }
    .text-secondary-700 {
      color: #da0e69;
    }

    .text-neutral-200 {
      color: #b1afba;
    }
    &.light-theme {
      background-color: #ffe3ef;
    }

    &.dark-theme {
      background-color: #000000;
    }
  }

  .info-container {
    display: flex;
    flex-direction: row wrap;
    align-items: center;
    justify-content: space-between;

    .average-rating,
    .review-count {
      font-size: 8px;
      font-weight: 200;
      &.light-theme {
        color: #130e3a;
        border-color: #130e3a;
      }

      &.dark-theme {
        color: #ffffff;
        border-color: #67647f;
      }

      .rating-value {
        font-weight: 600;
        color: #ff65aa;
      }
    }

    .average-rating {
      border-right: 1px solid;
      padding-right: 4px;
    }
    .review-count {
      padding-left: 4px;
    }
  }
}
