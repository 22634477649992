// SmallWidget.scss

.small-widget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  padding: 8px;
  border: 1px solid;
  border-radius: 4px;

  &.light-theme {
    background-color: #ffffff;
    border-color: #4056f7;
  }

  &.dark-theme {
    background-color: #130e3a;
    border-color: #67647f;
  }

  .level-text {
    font-weight: bold;
    font-size: 16px;

    &.light-theme {
      color: #4056f7;
    }

    &.dark-theme {
      color: #ffffff;
    }
  }

  .rating-container {
    .flex {
      display: flex;
    }

    .gap-1 {
      gap: 4px;
    }

    .rating-item {
      width: 18px;
      height: 18px;
      border-radius: 4px;

      &.light-theme {
        background-color: #ffe3ef;
        color: #f71278;
      }

      &.dark-theme {
        background-color: #fff;
        color: #f71278;
      }

      &.text-neutral-200 {
        color: #b1afba;
      }
    }
  }

  .logo-container {
    max-width: 18px;
    width: 100%;
    display: flex;
    align-items: center;

    .logo {
      width: 100%;
    }
  }
}
